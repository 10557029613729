<template>
  <!-- 使用插件预览的渲染器 -->
  <iframe
    :src="viewUrl"
    frameborder="0"
    class="plugin_preview_style iframe_framework"
    :height="iframeHeight"
    width="1000px"
  ></iframe>
</template>

<script>
import * as api from "./api.js";
export default {
  props: {
    showData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      viewUrl: "", // 非图片文件预览地址
      iframeHeight: 0,
      typeArr: [
        "pdf",
        "doc",
        "docx",
        "xlsx",
        "xls",
        "pptx",
        "ppt",
        "zip",
        "jpg",
        "jpeg",
        "png",
        "tiff",
        "mp4",
        "txt",
        "ofd",
        "rar",
        "rtf",
        "csv",
        "wps",
      ], // 支持预览的文件类型
    };
  },
  created() {
    this.usePluginPreview();
  },
  methods: {
    async usePluginPreview() {
      // 文件库
      const { type, fileType, file_info_id, id } = this.showData;
      if (type === "file") {
        /**
         * 如果是文件菜单下使用
         * 前端先判断是否支持的文件类型，如果不支持直接i提示不支持预览
         */
        if (this.typeArr.indexOf(fileType.toLowerCase()) === -1) {
          this.setnNotCanPreviewFn();
        }
      } else {
        /**
         * 文件列表
         * 客户详情页
         * 前端验证是否支持的文件
         */
        if (this.typeArr.indexOf(type.toLowerCase()) === -1) {
          this.setnNotCanPreviewFn();
          return;
        }
      }
      /**
       * 1、先在前端筛选一遍文件支持列表
       * 2、最后才要调用接口去查看是否支持
       */
      if (file_info_id) {
        await this.previewCheck(file_info_id);
      } else {
        await this.previewCheck(id);
      }
    },
    /**
     *  third preview if support
     *plugIns和flage 同时为true 可以预览，其他情况都提示不支持
     */
    async previewCheck(id) {
      let {
        data: { flage, plugIns, previewurl },
      } = await api.preview({
        id,
      });

      if (flage !== "true" || plugIns !== "true") {
        this.setnNotCanPreviewFn();
        return Promise.resolve(false);
      }

      this.viewUrl = previewurl;
      // framework 加载完成再关闭loading
      document
        .querySelector(".iframe_framework")
        .addEventListener("load", () => {
          this.$emit("loadSuccess");
        });

      /**
       * 使用iframe预览的高度需要动态设置
       */
      this.$nextTick(() => {
        let previewBoxHeight = document.querySelector(
          ".preview_content_inner"
        ).clientHeight;
        this.iframeHeight = previewBoxHeight + "px";
      });
      return Promise.resolve(true);
    },
    // 告诉使用者，不能被预览
    setnNotCanPreviewFn() {
      this.$emit("setnNotCanPreviewFn");
    },
  },
};
</script>

<style lang="scss" scoped>
// 插件预览样式，插件预览需要添加白色背景，解决背景透明问题
.plugin_preview_style {
  height: 100%;
  max-width: 100%;
  background-color: #fff;
}
</style>